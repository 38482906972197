import logo from './logo.svg';
import './App.css';
import React from "react"
import loadable from '@loadable/component'

import {  Route, Router, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';

import { history } from '../src/store/index';
const Layout = loadable(() => import('./components/layout'))
const HomeAboutSectionarea = loadable(() => import('./components/HomeAboutSection'))
const HomeSlider = loadable(() => import('./components/Slider'))
const ServiceSection = loadable(() => import('./components/HomeServices'))
const StatisticsSection = loadable(() => import('./components/Stats'))
const Gallery = loadable(() => import('./components/HomePageProjects'))
const Testimonials = loadable(() => import('./components/Testimonials'))
const BlogComponent =  loadable(() => import('./components/Blog-template'))
const Contact =  loadable(() => import('./pages/contact'))
const BlogPage =  loadable(() => import('./pages/blogs'))
const Discover =  loadable(() => import('./pages/ourcore'))
const BusinessSegment =  loadable(() => import('./pages/businesssegment'))
const Products =  loadable(() => import('./pages/products'))
const Imprints =  loadable(() => import('./pages/imprints'))
const CookiePolicy =  loadable(() => import('./pages/cookiepolicy'))
const Dataprotection =  loadable(() => import('./pages/dataprotection'))
const Validation =  loadable(() => import('./pages/validation'))
const Home =  loadable(() => import('./pages/home'))
const Download =  loadable(() => import('./components/downloadsContent'))
const Careers = loadable(()=>import('./pages/careers'))
function App() {
  ReactGA.initialize('UA-188852480-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
	return (
			<Router history={history}>
					<div>
          <Switch>
          <Route  exact path="/" component={Home}></Route>	
			  <Route  exact path="/blogs" component={BlogPage}></Route>	
        <Route  exact path="/contact" component={Contact}></Route>	
        <Route  exact path="/ourcore" component={Discover}></Route>
        <Route  exact path="/businesssegment" component={BusinessSegment}></Route>	
        <Route  exact path="/products" component={Products}></Route>	
        <Route  exact path="/blog/:id" component={BlogComponent}></Route>	
        <Route  exact path="/imprints" component={Imprints}></Route>	
        <Route  exact path="/cookiepolicy" component={CookiePolicy}></Route>	
        <Route  exact path="/dataprotection" component={Dataprotection}></Route>
        <Route  exact path="/validation" component={Validation}></Route>	
        <Route  exact path="/downloads" component={Download}></Route>	
        <Route  exact path="/careers" component={Careers}></Route>	
        
        	
          <Layout>
   
   </Layout>
   </Switch>
          </div>
				{/* </div> */}
			</Router>

		// </div>
	);
    }
export default App;
